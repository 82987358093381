.data-schema-page {
    .DataSchemaFields {
        .custom-listgroup {
            height: 714px;
            max-height: 714px;
        }
        .DataSchemaUploader {
            margin-top: 10px;
            .action-btn {
                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }
    .DataSchemaMappedFields {
        .card {
            margin-bottom: 0;
            height: 760px;
            max-height: 760px;
            overflow-y: scroll;
        }
        .DataSchemaFieldDetails {
            // height: 600px;
            // max-height: 600px;
            // overflow-y: scroll;
        }
    }
    .DataSchemaFieldDetails {
        // height: 421px;
        // max-height: 421px;
        // overflow: auto;
        .custom-shift-btn .btn {
            width: 38.33px;
        }
        .mandatoryField {
            margin-left: 15px;
        }
        [type='checkbox'][readonly] {
            pointer-events: none;
            opacity: 0.8;
        }
        .DatabaseField {
            background: #faebd7cc;
            padding-top: 15px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 0 35px 0;
        }
    }
}
.select-impact-data-fields-modal {
    .pagination-wrapper {
        display: none !important;
    }

    &.link-fund-modal .table-responsive.height-fixed.pagination-visible {
        height: 500px !important;
        .tbody {
            height: 445px;
        }
    }
    &.link-fund-modal .count {
        display: none;
    }
    &.link-fund-modal .table-responsive.height-fixed.mt-right-table {
        margin-top: 25px !important;
    }
}

.content-panel-editor .INTERACTION_PARENT,
.cp-preview-container.content-panel .INTERACTION_PARENT {
    &.container-fluid {
        background-color: transparent;
        .panelHeader .inner-positioner-header-panel-heading {
            background-color: transparent !important;
            div {
                color: #161616 !important;
            }
        }
    }
    .svc-question__content--selected:not(.svc-question__content--dragged) {
        box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-selected, 2px)
            var(
                --ctr-survey-question-panel-border-color-selected,
                var(--sjs-secondary-backcolor, var(--secondary, lightgray))
            );
        outline: none;
    }
    .svc-question__content:focus,
    .svc-hovered > .svc-question__content {
        box-shadow: 0 0 0 var(--ctr-survey-question-panel-border-width-selected, 2px)
            var(
                --ctr-survey-question-panel-border-color-selected,
                var(--sjs-secondary-backcolor, var(--secondary, lightgray))
            );
        outline: none;
    }
    .svc-page__content--selected.svc-page__content--selected,
    .svc-page__content:focus,
    .svc-hovered.svc-page__content {
        box-shadow: none !important;
        background: transparent !important;
    }
    .custom-button,
    .custom-button-remove {
        display: block !important;
    }
    .inputSliderLabel {
        .left {
            width: 30%;
            float: left;
            text-align: left;
        }
        .right {
            width: 30%;
            float: right;
            text-align: right;
        }
        .left span,
        .right span {
            font-size: var(--sjs-font-questiontitle-size, var(--sjs-font-size, 16px));
            line-height: calc(1.5 * (var(--sjs-font-questiontitle-size, var(--sjs-font-size, 16px))));
            font-family: var(
                --sjs-font-questiontitle-family,
                var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)))
            );
            // font-weight: var(--sjs-font-questiontitle-weight, 600);
            color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
        }
        .left span[contenteditable='true'],
        .right span[contenteditable='true'] {
            &:focus,
            &:focus-within,
            &:hover {
                box-shadow: 0px 2px 6px 0px var(--ctr-shadow-medium-color, rgba(0, 0, 0, 0.1)),
                    0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))))
                        var(--sjs-primary-backcolor, var(--primary, #19b394));
                border-radius: 2px;
            }
        }
    }
    .svc-side-bar--flyout {
        z-index: 100;
    }
    .svc-list__item [title='Smileys'],
    .svc-list__item:nth-child(8),
    .svc-list__item:nth-child(13),
    .svc-list__item:nth-child(14),
    .svc-list__item:nth-child(15),
    .svc-list__item:nth-child(16),
    .svc-list__item:nth-child(17),
    .svc-list__item:nth-child(18),
    .svc-list__item:nth-child(19),
    .svc-list__item:nth-child(20) {
        display: none !important;
    }
    .svc-toolbox__tool--action:nth-child(8),
    .svc-toolbox__tool--action:nth-child(13),
    .svc-toolbox__tool--action:nth-child(14),
    .svc-toolbox__tool--action:nth-child(15),
    .svc-toolbox__tool--action:nth-child(16),
    .svc-toolbox__tool--action:nth-child(17),
    .svc-toolbox__tool--action:nth-child(18),
    .svc-toolbox__tool--action:nth-child(19),
    .svc-toolbox__tool--action:nth-child(20),
    .svc-flex-column .svc-toolbox {
        display: none !important;
    }
    .sv-action-bar.svc-tab-designer__surface-toolbar .sv-action:nth-child(4),
    .sv-action-bar.svc-tab-designer__surface-toolbar .sv-action:nth-child(5),
    .sv-action-bar.svc-tab-designer__surface-toolbar .sv-action:nth-child(6) {
        display: none !important;
    }
    .sv-action[id='svd-settings'] {
        display: none !important;
    }
}
// // survey js css
// .INTERACTION_PARENT {
//     .svc-element__add-new-question .sv-svg-icon,
//     .svc-side-bar,
//     .svc-creator__banner,
//     .svc-toolbox__panel,
//     .svc-toolbar-wrapper,
//     .svc-page__footer,
//     [data-sv-drop-target-page='page2'],
//     .sd-btn--action.sd-navigation__complete-btn,
//     .sv-action-bar .sv-action,
//     .svc-designer-header {
//         display: none !important;
//     }
//     .sv-action-bar .sv-action[id='isrequired'] {
//         display: block !important;
//     }
//     .sd-page__title,
//     .sd-page__description {
//         visibility: hidden !important;
//     }
//     .survey-editor {
//         margin-bottom: 30px;
//         .sd-body.sd-body--static {
//             padding-top: calc(14 * (var(--sjs-base-unit, var(--base-unit, 8px))));
//             padding-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
//         }
//     }
//     .radiogroupWithoutText,
//     .dropdownWithoutText,
//     .checkboxWithoutText {
//         .svc-item-value--new:last-child {
//             display: none;
//         }
//     }
//     .sd-action,
//     .sd-selectbase__label {
//         display: flex !important;
//     }
//     .sd-body.sd-body--responsive {
//         padding-top: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
//     }
//     .sd-question.sd-question--table {
//         margin-top: 58px;
//     }
// }
// .content-panel-editor {
//     .INTERACTION_PARENT {
//         .panelHeader .inner-positioner-header-panel-heading {
//             background-color: transparent !important;
//             div {
//                 color: var(
//                     --sjs-font-questiontitle-color,
//                     var(--sjs-general-forecolor, var(--foreground, #161616))
//                 ) !important;
//             }
//         }
//     }
// }
.survey-configuration-form {
    [name='colSurveyConfigurationName'] {
        padding: 20px;
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
    .invalid-feedback {
        display: none !important;
    }
    input[readonly] {
        opacity: 0.8;
        pointer-events: none;
    }
}
.recipient-table {
    .not-started-color {
        color: red;
    }
    .in-progress-color {
        color: yellow;
    }
    .review-color {
        color: green;
    }
    .approved-color {
        color: yellowgreen;
    }
    .waiting-approved-color {
        color: yellowgreen;
    }
    .transferred-color {
        color: black;
    }
    .locked-color {
        color: pink;
    }
}
